import React, { useState, useEffect } from 'react';
import './agendamento.css';
import Menu from './../menu/menu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, changeToken, changeRedSair } from './../services/fieldActions';
import BlocoAgendamento from '../bloco-agendamento';
import { Redirect } from 'react-router-dom';
import { connection } from './../services/api';
import TratarData from './../funcoes/tratarData';
import Footer from './../footer/index';
import Lottie from 'react-lottie';
import animationData from './loading.json';
import * as $ from 'jquery';
import ModalPersonalizado from './../modal/index';
const defaultOptions = {
    loop: true,
    autoplay: true,
    resizeMode: 'contain',
    autoSize: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function Agendamento(props) {
    const [dados, setDados] = useState([]);
    const [dadosApresentado, setDadosApresentado] = useState([]);
    const [redSair, setRedSair] = useState(false);
    const [carregando, setCarregando] = useState(true);
    const [tamanhoPagina, setTamanhoPagina] = useState(6);
    const [pagina, setPagina] =  useState(0);
    const[texto, setTextoPag] = useState('');
    const [showAlerta, setShowAlerta] = useState(true);
    useEffect(()=>{paginar();},[pagina]);
    useEffect(()=>{ajustarBotoes();},[carregando, dadosApresentado]);
    function handleClickProx(){
        if (pagina < dados.length / tamanhoPagina - 1) {
            setPagina(pagina + 1);
        }
    }
    function handleClickAnt(){
        if (pagina > 0) {
            setPagina(pagina - 1);
        }
    }
    function ajustarBotoes(arrayDados = dados, pag = pagina) {
        $('#proximo').prop('disabled',
         arrayDados.length <= tamanhoPagina || pag >= Math.ceil(arrayDados.length / tamanhoPagina) - 1);
        $('#anterior').prop('disabled',
         arrayDados.length <= tamanhoPagina || pag === 0);
    }
    function paginar(arrayDados = dados, pag = pagina) {
        $('#paginacao').show();
        let dado = [];
        let cont = 0;
        for (var i = pag * tamanhoPagina;
             i < arrayDados.length &&
              i < (pag + 1) * tamanhoPagina; i++) {
                  cont++;
                arrayDados[i].id = cont;
            dado.push(arrayDados[i]);
        }
        setDadosApresentado([]);
        setDadosApresentado(dado);
        setTextoPag('Página ' + (pag + 1) + ' de ' + Math.ceil(arrayDados.length / tamanhoPagina));
    }
    useEffect(() => {
        props.changeStatus(false);
        async function getDados() {
            let returnError = false;
            let dadosApi = await (await connection(props.token)
                .post('/consultas',
                    { cpf: props.dadosIniciais.cpfNoMask })
                .catch(
                    err => {
                        console.log('Ocorreu um erro !');
                        props.changeRedSair(true);
                        setRedSair(true);
                        props.changeToken(null);
                        setCarregando(false);
                        return;
                    }
                ));
            if (returnError) {
                console.log('Ocorreu um erro !');
                props.changeRedSair(true);
                setRedSair(true);
                props.changeToken(null);
                setCarregando(false);
                return;
            }
            if (dadosApi === undefined) {
                console.log('Ocorreu um erro !');
                props.changeRedSair(true);
                setRedSair(true);
                props.changeToken(null);
                setCarregando(false);
                return;
            }
            dadosApi = dadosApi.data;
            if (dadosApi.status === true) {
                let cont = 0;
                let arrayMap = [];
                let arrayDados = [];
                arrayMap = dadosApi.data;
                arrayMap.map((data) => {
                    cont++;
                    arrayDados.push({
                        id: cont,
                        agenda: data.AGENDA,
                        status: data.STATUS,
                        data: TratarData(data.DATA)
                    });
                });
                setDados(arrayDados);
                paginar(arrayDados);
                setCarregando(false);
            } else {
                props.changeRedSair(true);
                setRedSair(true);
                props.changeToken(null);
                setCarregando(false);
                return;
            }

        }
        getDados();

    }, []);
    if (redSair) {
        return (
            <Redirect to='/sair' />
        );
    } else if (carregando) {
        return (
            <>
                <Menu nomePessoa={props.email} />
                <div className="carregandoDiv">
                    <div><Lottie options={defaultOptions} /></div>
                    <p className="legendaSpinner">Aguarde, trazendo agendamentos!</p>
                </div>
            </>
        );
    } else {
        return (
            <div className="background-principal">
                <Menu nomePessoa={props.email} />
                <ModalPersonalizado show={showAlerta} handleExit={()=>{setShowAlerta(false)}}/>
                <div className="container">
                    {dadosApresentado.map((element) => (
                            <BlocoAgendamento key={element.id} data={element} />
                    ))
                    }
                    {dadosApresentado.length !== 0 ?
                        <>
                            <div className="row justify-content-center fim" id="paginacao">
                                <button className="btn btn-agendamento"
                                onClick={()=>{handleClickAnt()}}
                                 id="anterior">Anterior</button>
                                <p id="numeracao">{texto}</p>
                                <button className="btn btn-agendamento"
                                onClick={()=>{handleClickProx()}} 
                                 id="proximo">Próximo</button>

                            </div>
                        </> : ''}
                    {
                        (dados.length === 0) ? <div className="blocoDadosNo">
                            <h3>Você não possui nenhum agendamento!</h3>
                        </div> : ''
                    }
                </div>
                <Footer />
            </div>
        );
    }

}
function mapStateToProps(state) {
    return {
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        token: state.aplicacaoAgendamento.token,
        email: state.aplicacaoAgendamento.email,
        redSair: state.aplicacaoAgendamento.redSair
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeToken, changeRedSair }, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(Agendamento);