import React, { useState, useEffect } from 'react';
import './cadastrar.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import EqualsString from './../funcoes/EqualsString';
import tratarEmail from './../funcoes/tratarEmail';
import MaskCpf from './../funcoes/maskCPF';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { changeStatus, changeEmail, changeToken,changeAreaCodigo } from './../services/fieldActions';
import { connectionWithoutToken } from './../services/api';
import Footer from './../footer/index';

function Cadastrar(props) {
    const [alerta, setAlerta] = useState({ msg: '', type: '' });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [carregado, setCarregado] = useState(false);
    const [carregarAtivarConta, setCarregarAtivarConta] = useState(false);
    const [enviandoServidor, setEnviandoServidor] = useState(true);
    const [alertForm, setAlertForm] = useState({password:{type:'', msg:''}, passwordConfirm:{type:'', msg:''}})
    async function registerServer() {
        setEnviandoServidor(true);
        let data = {
            email: email,
            senha: password,
            dataNascimento: props.dadosIniciais.dataNascimento,
            cpf: props.dadosIniciais.cpfNoMask
        };
        let returnError = false;
        let dados = (await connectionWithoutToken().post('/register', data).catch((err) => {
            setAlerta({
                msg: 'Ocorreu um erro ao comunicar com o servidor',
                type: 'alert alert-danger'
            });
            returnError = true;
        }));
        setEnviandoServidor(false);
        if (returnError) {
            return false;

        }
        dados = dados.data;

        if (dados.status === false) {

            if (dados.code === 2)
                setAlerta({ msg: dados.message, redi: <Link to="/login"> Ir Para Tela de Login </Link>, type: 'alert alert-danger' });
            if (dados.code === 1)
                setAlerta({ msg: dados.message, type: 'alert alert-danger' });
            if (dados.code === 3)
                setAlerta({ msg: dados.message, type: 'alert alert-danger' });
            if (dados.code === 4){
                props.changeAreaCodigo(true);
                setAlerta({ msg: 'Paciente já cadastrado, porém falta ativar a conta, pegue o código do seu email e vá para a tela de ativação de conta!', redi: <Link to="/codigo"> Ir para tela de ativação de conta </Link>, type: 'alert alert-danger' });
            }
            return false;

        } else {
            props.changeAreaCodigo(true);
            setCarregarAtivarConta(true);
            return true;
        }

    }

    async function enviar(e) {
        e.preventDefault();
        if (password.length < 6) {
            setAlerta({ msg: 'A senha deve conter no mínimo 6 caracteres', type: 'alert alert-danger' });
        } else if (EqualsString(password, passwordConfirm) === false) {
            setAlerta({ msg: 'Senhas não coincidem', type: 'alert alert-danger' })
        } else if (tratarEmail(email) === false) {
            setAlerta({ msg: 'e-mail não valído! ', type: 'alert alert-danger' })
        } else {
            setAlerta({ msg: 'Aguarde verificando informações', type: 'alert alert-warning' });
            if (await registerServer()) {
                setAlerta({ msg: '', type: '' });
            }
        }
    }
    function handleEmail(e) {
        setEmail(e.target.value);
        props.changeEmail(e.target.value);
    }
    function handlePassword(value){
        if(value.length < 6){
            setAlertForm({...alertForm, password:{type:'alert alert-danger',
                    msg:'A senha deve conter no minimo 6 caracteres '}});
        } else {
            setAlertForm({...alertForm, password:{type:'', msg:''}});
        }
        setPassword(value);
    }
    function botaoEnviar(){
        if(password.length < 6){
            setEnviandoServidor(true);
        } else if(passwordConfirm !== password){
            setEnviandoServidor(true);
        } else if (!email) {
            setEnviandoServidor(true);
        }
        else{
            setAlertForm({password:{type:'', msg:''}, passwordConfirm:{type:'', msg:''}});
            setEnviandoServidor(false);
        }
    }
    useEffect(()=>{botaoEnviar();},[email, passwordConfirm,password]);
    function handlePasswordConfirm(value){
        if(value !== password ){
            setAlertForm({...alertForm, passwordConfirm:{type:'alert alert-danger',
                    msg:'Senhas não coincidem'}});
        } else {
            setAlertForm({...alertForm, passwordConfirm:{type:'',
                    msg:''}});
        }
        setPasswordConfirm(value);
    }
    useEffect(() => {
        async function getDados() {
            setCarregado(true);

        }
        getDados();
    }, []);

    if (!props.permissao && carregado) {
        return (
            <>
                <Redirect to={{ pathName: '/', state: { from: props.location } }} />
            </>

        );
    } else if (!carregado) {
        return (
            <>
                <div className="carregandoDiv">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="legendaSpinner">Aguarde, verificando permissão!</p>
                </div>
            </>
        );
    } else if (carregarAtivarConta) {
        return (
            <>
                <Redirect to="/codigo" />
            </>
        );
    } else if (props.token) {
        return (
            <>
                <Redirect to="/agendamento" />
            </>
        );
    } else {
        return (
            <>
                <div className="all">
                    <div className="container">
                        <div className="login">
                            <div className="header">
                                <h6>SISTEMA PARA CONSULTA DE AGENDA</h6>
                                <p className="subtitulo">Nossas equipes estão avaliando caso a caso diariamente. Portanto, confira o seu agendamento em até
    <b> 24 hrs</b> antes da data marcada. <b>Assim, você garante que o seu procedimento está confirmado.</b></p>
                            </div>
                            <div className="body">
                                <h2 className="sub-titulo">Registre-se</h2>
                                <p className="Fontblack">CPF:
                                {MaskCpf(props.dadosIniciais.cpfNoMask)} <b>{}</b></p>
                                <div className={alerta.type}>
                                    <p className="Fontblack">{alerta.msg}</p>
                                    {(alerta.redi !== undefined) ? alerta.redi : ''}
                                </div>
                                <form onSubmit={enviar}>
                                    <label htmlFor="email">Email <b>*Deve ser único por paciente</b></label>
                                    <div className="form-group form-inline">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">
                                                    email
                                        </span>
                                            </span>
                                        </div>
                                        <input className="form-control" type="email" id="email" value={email} onChange={e => { handleEmail(e) }} required />
                                    </div>
                                    <label htmlFor="password">Senha</label>

                                    <div className="form-group form-inline">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">
                                                    https
                                        </span>
                                            </span>
                                        </div>
                                        <input className="form-control" type="password" id="password"
                                               value={password}
                                               onChange={e => handlePassword(e.target.value)} required />
                                    </div>
                                    <div className={alertForm.password.type}>
                                        {alertForm.password.msg}
                                    </div>
                                    <label htmlFor="passwordConfirm">Confirmar senha</label>
                                    <div className="form-group form-inline">

                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">
                                                    https
                                        </span>
                                            </span>
                                        </div>
                                        <input className="form-control" type="password" id="passwordConfirm"
                                               value={passwordConfirm}
                                               onChange={e => handlePasswordConfirm(e.target.value)} required />
                                    </div>
                                    <div className={alertForm.passwordConfirm.type}>
                                        {alertForm.passwordConfirm.msg}
                                    </div>
                                    <div className="espacamento row justify-content-between">
                                        <Link className="btn btn-agendamento-2" to="/sair">Não é essa conta?</Link>
                                        <button className="btn btn-agendamento" disabled={enviandoServidor}>Enviar</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );


    }
}
function mapStateToProps(state) {
    return {
        permissao: state.aplicacaoAgendamento.permissao,
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        token: state.aplicacaoAgendamento.token
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeEmail, changeToken,changeAreaCodigo }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Cadastrar);