//importações essenciais react
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import * as serviceWorker from './serviceWorker';


//componentes para importar
import App from './components/home/App';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import EsqueciSenha from './components/esqueci-senha/EsqueciSenha';
import Login from './components/login/login';
import Cadastrar from './components/cadastrar/cadastrar';
import Agendamento from './components/agendamentos/agendamento';
import AlterarSenha from './components/alterar-senha/index';
import Component404 from './components/errors/404/index';
import Codigo from './components/codigo';
import PrivateRouter from './components/PrivateRouter';
import Chat from './components/chat';
//redux imports 
import { store, persistor } from './components/services/combinersRedux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Sair from './components/sair';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Route path="/" exact={true} component={App} />
          <Route path="/esqueci-senha" component={EsqueciSenha} />
          <Route path="/login" component={Login} />
          <Route path="/cadastrar" component={Cadastrar} />
          <Route path="/codigo" component={Codigo} />
          <PrivateRouter path="/agendamento" component={Agendamento} />
          <PrivateRouter path="/alterar-senha" component={AlterarSenha} />
          <Route path="/sair" component={Sair} />
          {/* <Chat/> */}
        </PersistGate>
      </Provider>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
