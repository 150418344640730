import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, changeToken, changeRedSair } from './../services/fieldActions';
import Menu from './../menu/menu';
import { connection } from './../services/api';
import { Redirect } from 'react-router';
import Footer from './../footer/index';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from './loading.json';
import './style.css';
const defaultOptions = {
    loop: true,
    autoplay: true,
    resizeMode: 'contain',
    autoSize: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
function AlterarSenha(props) {
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senhaNova, setSenhaNova] = useState('');
  const [confirmarSenhaNova, setConfirmarSenhaNova] = useState('');
  const [alerta, setAlerta] = useState({ msg: '', type: '' });
  const [redSair, setRedSair] = useState(false);
  const [enviandoServidor, setEnviandoServidor] = useState(false);
  function submitForm(e) {
    
    e.preventDefault();
    if (senhaNova !== confirmarSenhaNova) {
      setAlerta({
        msg: 'A confirmação da senha não confere',
        type: 'alert alert-danger'
      });
    } else if (senhaNova.length < 6) {
      setAlerta({
        msg: 'A senha deve conter no mínimo 6 caracteres!',
        type: 'alert alert-danger'
      });
    } else {
      setAlerta({ msg: '', type: '' });
      changeSenha();
    }
  }
  async function testConnection() {
    let returnError = false;
    let dadosApi = await (await connection(props.token)
      .post('/consultas',
        { cpf: props.dadosIniciais.cpfNoMask })
      .catch(
        (err) => {
          returnError = true;
        }
      ));
    if (returnError) {
      console.log('Ocorreu um erro !');
      props.changeRedSair(true);
      setRedSair(true);
      props.changeToken(null);
      return;
    }
    if (dadosApi.data === undefined) {
      console.log('Ocorreu um erro !');
      props.changeRedSair(true);
      setRedSair(true);
      props.changeToken(null);
      return;
    }
  }
  useEffect(() => {
    props.changeStatus(false);
    testConnection();
  }, []);
  async function changeSenha() {
    setEnviandoServidor(true);
    setAlerta({ msg: 'Aguarde !', type: 'alert alert-warning' });
    let returnError = false;
    let dados = await connection(props.token).post('/changepassword', {
      email: props.email,
      senha_antiga: senhaAntiga,
      senha_nova: senhaNova
    }).catch((res) => {
      returnError = true;
    });
    if (returnError) {
      props.changeToken(null);
      props.changeRedSair(true);
      setRedSair(true);
      return;
    }
    if (dados.data === undefined) {
      props.changeToken(null);
      props.changeRedSair(true);
      setRedSair(true);
      return;
    }
    dados = dados.data;
    if (dados.status === true) {
      setAlerta({ msg: 'Foi alterado com sucesso!', type: 'alert alert-success' });
    } else {
      setAlerta({ msg: 'A senha não corresponde com a senha atual!', type: 'alert alert-danger' });
    }
    setEnviandoServidor(false);

  }

  if (redSair) {
    return (
      <>
        <Redirect to="/login" />
      </>
    );
  }
  return (
    <>
      <Menu nomePessoa={props.email} />
      <div className="container">
        <div className="bloco-agendamento">
          <div className="header">
            <h2>Alterar Senha</h2>
          </div>
          <div className="body">
            <Link  className="btn btn-agendamento-2" to="/agendamento">Voltar</Link>
            <hr />
            {(!enviandoServidor)?<div className={alerta.type}>{alerta.msg}</div>
            :''}
            {(enviandoServidor)?
            <div><Lottie options={defaultOptions}/></div>:''}
            {(!enviandoServidor)?
            <form onSubmit={submitForm}>
              <label htmlFor="senhaAntiga">Senha Antiga</label>
              <div className="form-group form-inline">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="material-icons">
                      https
                                        </span>
                  </span>
                </div>
                <input className="form-control" type="password" id="senhaAntiga" value={senhaAntiga}
                  onChange={e => setSenhaAntiga(e.target.value)} required />
              </div>
              <label htmlFor="senhaNova">Nova Senha</label>
              <div className="form-group form-inline">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="material-icons">
                      https
                                        </span>
                  </span>
                </div>
                <input className="form-control" type="password" id="senhaNova" value={senhaNova}
                  onChange={e => setSenhaNova(e.target.value)} required />
              </div>
              <label htmlFor="confirmarSenhaNova">Confirmar Nova Senha</label>
              <div className="form-group form-inline">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="material-icons">
                      https
                                        </span>
                  </span>
                </div>
                <input className="form-control" type="password" id="confirmarSenhaNova" value={confirmarSenhaNova}
                  onChange={e => setConfirmarSenhaNova(e.target.value)} required />
              </div>
              <div className="row justify-content-center">
                <button className="btn btn-agendamento" disabled={enviandoServidor}>Alterar!</button>
              </div>
            </form>:''}
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    permissao: state.aplicacaoAgendamento.permissao,
    dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
    token: state.aplicacaoAgendamento.token,
    email: state.aplicacaoAgendamento.email,
    redSair: state.aplicacaoAgendamento.redSair
  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeStatus, changeToken, changeRedSair }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenha);