import React, { useState, useEffect } from 'react';
import './style.css';
import { exibirFormulario } from './functions';
import { iniciar } from "./functions";
import Ballon from './img/balloon.png';
import Arrow from './img/arrow.png';
import MaskedInput from 'react-text-mask';
import useInterval from '../services/useInterval'

const Chat = () => {
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [info, setInfo] = useState('');
  const [prontuario, setProntuario] = useState('');
  const [fechado, setFechado] = useState(false);
  const [inPage, setInpage] = useState(true);
  useEffect(() => {
    let jsonString = JSON.stringify({ "t": "Dados adicionais", "c": [{ "Email": email }, { "Nome": nome }, { "CPF": cpf.replaceAll('.', '').replaceAll('-', '') }, { "Origem": "Agendamento" }, { "Prontuário": prontuario }] });
    setInfo(jsonString);
  }, [cpf, email, nome, info, prontuario]);
  useEffect(() => {
    let i = cpf.length;
    let cpfV = cpf;
    if (i === 3 || i === 7) {
      cpfV = cpf + '.';
    }
    else if (i === 11) {
      cpfV = cpf + '-';
    }
    cpfV = cpfV.replaceAll('.', '').replaceAll('-', '');
    let jsonString = JSON.stringify({ "t": "Dados adicionais", "c": [{ "Email": email }, { "Nome": nome }, { "Origem": "Agendamento" }, { "CPF": cpfV }, { "Prontuário": prontuario }] });
    setInfo(jsonString);
  }, [cpf]);
  function verifyHours() {
    let date = new Date();
    let hours = date.getHours();
    if (hours >= 18 || hours < 8)
      setFechado(true);
    else 
      setFechado(false);
  }
  
  useEffect(() => {
    verifyHours();
    setInpage(true);
    return () => {
      setInpage(false);
    }
  }, []);
  
  useInterval(verifyHours, inPage ? 3000 : null);
  return (
    <div className="btn-popup">
      <a id="formHeader" className="btn-pop btn1 btn-danger" onClick={exibirFormulario}><img className="icon-ballon" src={Ballon} /><span className="titulo-chat"><b>FALE CONOSCO,</b><span className="chat-font"> {fechado ? "CHAT OFFLINE!" : "CHAT ONLINE!"} </span><img src={Arrow} /></span></a>
      <div id="divForm" className="form hidden">

        <form id="formulario-chat" method="POST" action="https://chat.digitro.erastogaertner.com.br/chatclient/chat.php" encType="multipart/form-data; charset=utf-8"
          onSubmitCapture={() => {
            return iniciar();
          }}
        >
          <input id="info" value={info} name="info" onChange={() => { }} hidden />
          <input id="beep" name="beep" value="1" onChange={() => { }} hidden />
          <input id="notif" name="notif" value="1" onChange={() => { }} hidden />
          <input id="mi" name="mi" value="Bem-vindo à Central de Relacionamento Erasto Gaertner" onChange={() => { }} hidden />
          <table>
            <tbody>
              <div className="horarioAtendimento">
                <p>HORÁRIO DE ATENDIMENTO: 08:00 às 18:00 horas
                <br />
                  <span className="observacaoTempoHorarioAtendimento">(segunda a sexta-feira)</span>
                </p>

              </div>
              
                <tr><td><b>Nome:</b></td></tr>
                <tr><td><input id="ci" name="ci" type="text" className="form-control" onChange={(e) => setNome(e.target.value)} value={nome} style={{ width: '92%' }} required /></td></tr>
                <tr><td><b>CPF:</b></td></tr>
                <tr><td>
                  <MaskedInput
                    mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                    className="form-control"
                    onChange={e => setCpf(e.target.value)}
                    value={cpf}
                    id="cpf"
                    required
                  />
                </td></tr>
                <tr><td><b>Número prontuario:</b></td></tr>
                <tr><td><input id="prontuario"
                  onChange={(e) => setProntuario(e.target.value)}
                  value={prontuario}
                  type="text" className="form-control" style={{ width: '92%' }} /></td></tr>
                <tr><td>
                  <select id="servico" name="servico" className="form-control" style={{ display: 'none' }}>
                    <option value="Central de Relacionamento">CENTRAL DE RELACIONAMENTO</option>
                  </select>
                </td></tr>
                <tr>
                  <td align="center">
                    <input type="submit" name="" value={fechado ? "CHAT OFFLINE" : "INICIAR"} className="btn btn-iniciar" disabled={fechado} />
                    </td>
                    </tr>
              
              
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}

export default Chat;
