export function iniciar(event){
  new chat();
  return false;
}
function chat(){
  var form = document.querySelector('#formulario-chat');
  
  //var newForm = this.createForm(form);//a
  form.target = this.abrirPopup();
  //
  //this.addChatClientForm(newForm);//FF/IE bug
  //newForm.submit();
  form.submit();
  
  return false;
}

chat.prototype.addChatClientForm = function(form) {
  form.style.display = "none";
  document.body.appendChild(form);
  return;
  if (document.forms.length == 1) {
    document.body.appendChild(form);
  } else {
    if(document.forms[1] !== undefined && document.forms[1] !== null){
      document.body.removeChild(document.forms[1]);
      document.body.appendChild(form);
    }
  }
}
chat.prototype.createForm = function(form) {
  var els = form.elements;
  var el;
  var newForm = document.createElement("form");
  newForm.method = form.method;
  newForm.action = form.action;
  for (var i = 0; i < els.length; i++) {
    el = els[i];
    if(!el.disabled && el.value != "") {
      var element = document.createElement("input");
      element.name=el.name;
      element.value=el.value;
      newForm.appendChild(element);
    }
  }
  return newForm;
}
chat.prototype.abrirPopup = function() {
  var popupName ="chatclient" + (new Date().getTime());
  var p = "width=405,height=486,status=no,resizable=yes,toolbar=no,location=yes,menubar=no,titlebar=no,directories=no";

  window.open("",popupName,p);
  return popupName;
}
chat.prototype.cloneForm = function(formBase) {
  var form = formBase.cloneNode(true);
  var elsBase = formBase.elements;
  var els = form.elements;
  var el;
  for (var i = 0; i < els.length; i++) {
    el = els[i];
    el.value = elsBase[i].value;
  }
  form.removeAttribute("onSubmit");
  return form;
}
export function exibirFormulario(){
  var divForm =  document.getElementById('divForm');
  var formHeader =  document.getElementById('formHeader');
  if(divForm.className == 'form hidden'){
    divForm.className = 'form';
    formHeader.className = 'btn btn1 btn-danger open';
  }
  else{
    divForm.className = 'form hidden';
    formHeader.className = 'btn btn1 btn-danger';
  }
}
