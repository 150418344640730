import React, { useState, useEffect } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { bindActionCreators } from 'redux';
import {
  changeStatus, changeEmail,
  changeToken, changeRedSair, changeDados,
  changeAreaCodigo
} from './../services/fieldActions';
import { connectionWithoutToken } from './../services/api';
import Footer from './../footer/index';



function Codigo(props) {
  const [alerta, setAlerta] = useState({ msg: '', type: '' });
  const [code, setCode] = useState('');
  const [cpf, setCpf] = useState('');
  const [redirecionar, setRedirecionar] = useState(false);
  const [enviandoServidor, setEnviandoServidor] = useState(false);
  async function enviar(event) {
    event.preventDefault();

    if(!cpf || !code){
      return;
    }
    setEnviandoServidor(true);
    setAlerta({
      msg: 'Aguarde, analisando informações',
      type: 'alert alert-warning'
    });
    var returnError = false;
    var noMaskCpf = cpf.replace(/[^\d]+/g, '');
    let dados = await (await connectionWithoutToken().post('/ativar-conta',
      {
        code: code,
        cpf: noMaskCpf
      }).catch(
        (err) => {
          setAlerta({
            msg: 'Ocorreu um erro ao comunicar com o servidor',
            type: 'alert alert-danger'
          });
          returnError = true;
        }
      ));
    setEnviandoServidor(false);
    if (returnError) {
      return false;
    }
    dados = dados.data;
    if (!dados) {
      setAlerta({
        msg: 'Ocorreu um erro ao comunicar com o servidor',
        type: 'alert alert-danger'
      });
    }
    if (dados.status === false) {
      setAlerta({
        msg: dados.msg,
        type: 'alert alert-danger'
      });
    } else {
      setAlerta({ msg: 'Validado com sucesso!', type: 'alert alert-success' });
      props.changeToken(dados.data);
      props.changeDados({ cpfNoMask: dados.cpf });
      props.changeEmail(dados.email);
      props.changeAreaCodigo(false);
      setRedirecionar(true);
      setAlerta({ msg: '', type: '' });
    }

  }
  async function enviarEmail(){
      if(cpf.length <= 0){
        setAlerta({type:'alert alert-danger', msg:"Precisa inserir o campo de CPF!"})
      }
    setEnviandoServidor(true);
    setAlerta({
      msg: 'Aguarde, analisando informações',
      type: 'alert alert-warning'
    });
    var returnError = false;
    var noMaskCpf = cpf.replace(/[^\d]+/g, '');
    let dados = await (await connectionWithoutToken().post('/submit-code',
      {
        cpf: noMaskCpf
      }).catch(
        (err) => {
          setAlerta({
            msg: 'Ocorreu um erro ao comunicar com o servidor',
            type: 'alert alert-danger'
          });
          returnError = true;
        }
      ));
    setEnviandoServidor(false);
    if (returnError) {
      return false;
    }
    dados = dados.data;
    if (!dados) {
      setAlerta({
        msg: 'Ocorreu um erro ao comunicar com o servidor',
        type: 'alert alert-danger'
      });
    }
    if (dados.status === false) {
      setAlerta({
        msg: dados.msg,
        type: 'alert alert-danger'
      });
    } else {
      setAlerta({ msg: dados.msg, type: 'alert alert-success' });
    }

  }
  if (redirecionar) {
    return (
      <Redirect to="/agendamento" />
    );
  } else if (props.token) {
    return (
      <Redirect to="/agendamento" />
    );
  } else if (!props.areaCodigo) {
    return (
      <Redirect to="/agendamento" />
    );
  } else {
    return (
      <div className="all">

        <div className="container">
          <div className="login">
            <div className="header">
              <h6>SISTEMA PARA CONSULTA DE AGENDA</h6>
              <p className="subtitulo">Caso você não tenha agenda durante os próximos meses
              o seu cadastro não será encontrado.</p>
            </div>
            <div className="body">
              <h2 className="subtitulo">Ativação de conta!</h2>
              
              * Foi enviado um email para você contendo um código de ativação, 
              por favor preencha abaixo o código recebido por email e também o CPF ! <br/>
                <i>caso não tenha recebido, preencha o campo CPF e clique em reenviar código !</i>
              <div className={alerta.type}>
                <p className="Fontblack">{alerta.msg}</p>
              </div>
              <form onSubmit={enviar}>
                <div>
                  <label htmlFor="cpf">CPF</label>
                  <div className="form-group form-inline">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <span className="material-icons">
                          account_circle
                    </span>
                      </span>
                    </div>
                    <MaskedInput
                      mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                      className="form-control"
                      placeholder="CPF"
                      onChange={e => setCpf(e.target.value)}
                      value={cpf}
                      id="cpf"
                      required
                    />
                  </div>
                </div>
                <label htmlFor="codigo">Código</label>
                <div className="form-group form-inline">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="material-icons">
                        assignment_turned_in
                      </span>
                    </span>
                  </div>
                  <input type="text"
                    placeholder="Código"
                    id="codigo"
                    className="form-control"
                    name="codigo" value={code}
                    onChange={e => setCode(e.target.value)}
                    required
                    />
                </div>

                <div
                  className="espacamento row justify-content-between">
                  <button className="btn btn-agendamento" type="button" 
                  onClick={()=>{
                    enviarEmail();
                  }}
                    disabled={enviandoServidor}>Reenviar Código</button>
                  <button className="btn btn-agendamento"
                    disabled={enviandoServidor}>Enviar</button>
                </div>
                <div className="espacamento row justify-content-between">
                  <Link to="/sair" className="btn btn-agendamento-2">
                    Não é cadastrado? </Link>
                </div>
              </form>
            </div>

          </div>
        </div>
        <Footer />
      </div>
    );
  }

}
function mapStateToProps(state) {
  return {
    permissao: state.aplicacaoAgendamento.permissao,
    dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
    redSair: state.aplicacaoAgendamento.redSair,
    areaCodigo: state.aplicacaoAgendamento.areaCodigo

  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeStatus,
    changeEmail, changeToken, changeDados,
    changeRedSair, changeAreaCodigo
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Codigo);