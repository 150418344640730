import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeStatus, changeToken, changeAreaCodigo } from './../services/fieldActions';
import { Redirect } from 'react-router-dom';
function Sair(props){
    useEffect(()=>{
        props.changeToken('');
        props.changeStatus(false);
        props.changeAreaCodigo(false);
    },[]);
    return (
        <Redirect to={{pathName:'/', state:{from:props.location}}} />
    );
}

function mapStateToProps(state) {
    return {
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        token: state.aplicacaoAgendamento.token,
        email:state.aplicacaoAgendamento.email,
        areaCodigo:state.aplicacaoAgendamento.areaCodigo
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeToken, changeAreaCodigo }, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(Sair);
