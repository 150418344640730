export function changeStatus(status){
    return {
        type:'TOOGLE_PERMISSAO',
        payload: {
            permissao:status
        }
    }
}

export function changeDados(dados){
    return {
        type:'TOOGLE_DADOSINICIAIS',
        payload:{
            dadosIniciais:dados
        }
    }
}
export function changeRedSair(status){
    return {
        type:'TOOGLE_REDSAIR',
        payload:{
            status:status
        }
    }
}
export function changeToken(token){
    return {
        type:'TOOGLE_TOKEN',
        payload:{
            token:token
        }
    }
}
export function changeEmail(email){
    return {
        type:'TOOGLE_EMAIL',
        payload:{
            email:email
        }
    }
}
export function changeAreaCodigo(statusCodigo){
    return{
        type:'TOOGLE_AREACODIGO',
        payload:{
            areaCodigo:statusCodigo
        }
    }
}