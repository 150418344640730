import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import './App.css';
import tratarCPF from './../funcoes/tratarCPF';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, changeDados, changeToken } from './../services/fieldActions';
import { connectionWithoutToken } from './../services/api';
import Footer from './../footer/index';
import ModalPersonalizado from './../modal/index';
function App(props) {
  const [cpf, setCpf] = useState('');
  const [data_nascimento, setDataNascimento] = useState('');
  const [alerta, setAlerta] = useState({ msg: '', type: '' });
  const [tipo, setTipo] = useState(0);
  const [prontuario, setProntuario] = useState('');
  const [login, setLogin] = useState(false);
  const [enviandoServidor, setEnviandoServidor] = useState(false);
  const [showAlerta, setShowAlerta] = useState(true);
  async function submitServer() {
    setEnviandoServidor(true);
    var noMaskCpf = cpf.replace(/[^\d]+/g, '');
    let dataNasc = data_nascimento;
    var returnError = false;
    setAlerta({ msg: 'Aguarde, validando informações', type: 'alert alert-warning' });
    let dados = await (await connectionWithoutToken().post('/auth/verify', {
      dataNascimento: data_nascimento,
      cpf: noMaskCpf,
      prontuario: prontuario
    }).catch(err => {
      setAlerta({
        msg: 'Ocorreu um erro ao comunicar com o servidor',
        type: 'alert alert-danger'
      });
      returnError = true;
    }));
    setEnviandoServidor(false);
    if (returnError) {
      return false;
    }
    dados = dados.data;
    if (dados.status === false) {
      if (dados.code === 1) {
        setAlerta({ msg: dados.message, type: 'alert alert-danger' });
        return false;
      } else {
        setAlerta({
          msg: 'Você já está cadastrado! vá para a tela de login',
          redi: <Link to="/login"> Ir Para Tela de Login </Link>,
          type: 'alert alert-danger'
        });
        return false;
      }
    } else if (dados[0].status === false) {

      if (dados[0].code === 1) {
        setAlerta({
          msg: dados[0].message,
          type: 'alert alert-danger'
        });
        return false;
      } else if (dados[0].code === 4) {
        setAlerta({ msg: 'Paciente já cadastrado, porém falta ativar a conta, pegue o código do seu email e vá para a tela de ativação de conta!', redi: <Link to="/codigo"> Ir para tela de ativação de conta </Link>, type: 'alert alert-danger' });
        return false;
      } else {
        setAlerta({
          msg: 'Você já está cadastrado! vá para a tela de login',
          redi: <Link to="/login"> Ir Para Tela de Login </Link>,
          type: 'alert alert-danger'
        });
        setLogin(true);
        return false;
      }
    } else if (dados[0].status === true) {
      setAlerta({ msg: 'Ok!', type: 'alert alert-success' });
      let newDate = {
        cpfNoMask: dados[0].data.nr_cpf,
        cpf: cpf,
        prontuario: prontuario,
        dataNascimento: dataNasc
      };
      props.changeDados(newDate);
      return true;
    } else {
      setAlerta({ msg: 'Ocorreu um erro com o servidor', type: 'alert alert-danger' });
      return false;
    }
  }
  async function enviar(e) {
    e.preventDefault();
    if (tipo === 1 && !tratarCPF(cpf)) {
      setAlerta({
        msg: 'CPF não válido ! verifique o campo inserido',
        type: 'alert alert-danger'
      });
      return false;
    } else {
      setAlerta({ msg: '', type: '' });
    }
    if (await submitServer()) {

      props.changeStatus(true);
    }




  }

  function handleCpf(e) {
    setCpf(e.target.value);
  }
  function handleTipoLogin(e) {
    setCpf('');
    setProntuario('');
    setTipo(Number(e.target.value));
  }
  if (props.permissao) {
    return (
      <Redirect to="/cadastrar" />
    );
  }
  if (props.token) {
    return (
      <Redirect to="/agendamento" />
    );
  }
  if (props.areaCodigo) {
    return (
      <Redirect to="/codigo" />
    );
  }
  if (login) {
    return (
      <Redirect to="/login" />
    );
  }
  return (
    <div className="all">
      <ModalPersonalizado show={showAlerta} handleExit={()=>{setShowAlerta(false)}}/>
      <div className="container">
        <div className="login">
          <div className="header">
            <h6>SISTEMA PARA CONSULTA DE AGENDA</h6>
            <p className="subtitulo">Nossas equipes estão avaliando caso a caso diariamente. Portanto, confira o seu agendamento em até
    <b> 24 hrs</b> antes da data marcada. <b>Assim, você garante que o seu procedimento está confirmado.</b></p>
          </div>
          <div className="body">
            <h2 className="subtitulo">Registre-se</h2>
            <div className={alerta.type}>
              <p className="Fontblack">{alerta.msg}</p>
              {(alerta.redi !== undefined) ? alerta.redi : ''}
            </div>
            <form onSubmit={enviar}>
              <div className="form-group">
                <select className="form-control" onChange={e => { handleTipoLogin(e) }} required>
                  <option value="">Selecione</option>
                  <option value="1">CPF</option>
                  <option value="2">Prontuario</option>
                </select>
              </div>
              {(tipo === 1) ?
                <div>
                  <label htmlFor="cpf">CPF</label>
                  <div className="form-group form-inline">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <span className="material-icons">
                          account_circle
                    </span>
                      </span>
                    </div>
                    <MaskedInput
                      mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                      className="form-control"
                      onChange={e => handleCpf(e)}
                      value={cpf}
                      id="cpf"
                      required
                    />
                  </div>
                </div>
                : (tipo === 2) ?
                  <div>
                    <label htmlFor="prontuario">Prontuario</label>
                    <div className="form-group form-inline" >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <span className="material-icons">
                            account_circle
                    </span>
                        </span>
                      </div>
                      <input className="form-control" onChange={e => setProntuario(e.target.value)} id="prontuario" value={prontuario} placeholder="Prontuario" required />
                    </div>
                  </div>
                  : ''
              }
              <label htmlFor="data_nascimento">Data de nascimento</label>
              <div className="form-group form-inline">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="material-icons">
                      calendar_today
                    </span>
                  </span>
                </div>

                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                  className="form-control"
                  onChange={e => setDataNascimento(e.target.value)}
                  value={data_nascimento}
                  id="data_nascimento"
                  required
                />
              </div>
              <div className="espacamento row justify-content-between">
                <Link to="/login" className="btn btn-agendamento-2" > Já tem o cadastro? </Link>
                <button className="btn btn-agendamento" disabled={enviandoServidor}> Enviar </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}
function mapStateToProps(state) {
  return {
    permissao: state.aplicacaoAgendamento.permissao,
    token: state.aplicacaoAgendamento.token,
    areaCodigo: state.aplicacaoAgendamento.areaCodigo

  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeStatus, changeDados, changeToken }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
