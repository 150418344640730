import React, { useState, useEffect } from 'react';
import './login.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { changeStatus, changeEmail, 
    changeToken, changeRedSair, changeDados,
    changeAreaCodigo
 } from './../services/fieldActions';
import { connectionWithoutToken } from './../services/api';
import Footer from './../footer/index';
import ModalPersonalizado from './../modal/index';
function Login(props) {
    const [alerta, setAlerta] = useState({ msg: '', type: '' });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [redirecionar, setRedirecionar] = useState(false);
    const [enviandoServidor, setEnviandoServidor] = useState(false);
    const [alertaSenha, setAlertaSenha]  = useState({msg:'', type:''});
    const [showAlerta, setShowAlerta] = useState(true);
    
    async function enviar(event) {
        setEnviandoServidor(true);
        event.preventDefault();
        setAlerta({ msg: 'Aguarde, analisando informações', type: 'alert alert-warning' });
        var returnError = false;
        let dados = await (await connectionWithoutToken().post('/auth',
            {
                email: email,
                senha: password
            }).catch(
                (err) => {
                    setAlerta({
                        msg: 'Ocorreu um erro ao comunicar com o servidor',
                        type: 'alert alert-danger'
                    });
                    returnError = true;
                }
            ));
        setEnviandoServidor(false);
        if (returnError) {
            return false;
        }
        dados = dados.data;
        if (!dados) {
            setAlerta({
                msg: 'Ocorreu um erro ao comunicar com o servidor',
                type: 'alert alert-danger'
            });
        }
        if (dados.status === false) {
            if (dados.code) {
                props.changeAreaCodigo(true);
            } else {
                setAlerta({ msg: 'Credenciais inválidas', 
                type: 'alert alert-danger' });
            }

        } else {
            setAlerta({ msg: 'Ok!', type: 'alert alert-success' });
            props.changeToken(dados[0].data);
            props.changeDados({ cpfNoMask: dados[0].cpf });
            props.changeEmail(email);
            setRedirecionar(true);
            setAlerta({ msg: '', type: '' });
        }

    }
    useEffect(() => {
        if (props.redSair) {
            getRedSair();
        }
    }, []);
    function getRedSair() {
        setAlerta({
            msg: 'Acabou sua sessão inicie o login novamente!',
            type: 'alert alert-danger'
        })
        props.changeRedSair(false)

    }
    function handlePassword(value){
        setPassword(value);

    }

    if (redirecionar) {
        return (
            <Redirect to="/agendamento" />
        );
    } else if (props.token) {
        return (
            <Redirect to="/agendamento" />
        );
    } else if (props.areaCodigo){
        return (
            <Redirect to="/codigo" />
        );
    } else {
        return (
            
            <div className="all">
                <ModalPersonalizado show={showAlerta} handleExit={()=>{setShowAlerta(false)}}/>
                <div className="container">
                    <div className="login">
                        <div className="header">
                            <h6>SISTEMA PARA CONSULTA DE AGENDA</h6>
                            <p className="subtitulo">Nossas equipes estão avaliando caso a caso diariamente. Portanto, confira o seu agendamento em até
    <b> 24 hrs</b> antes da data marcada. <b>Assim, você garante que o seu procedimento está confirmado.</b></p>
                        </div>
                        <div className="body">
                            <h2 className="subtitulo">Login</h2>
                            <div className={alerta.type}>
                                <p className="Fontblack">{alerta.msg}</p>
                            </div>
                            <form onSubmit={enviar}>
                                <label htmlFor="email">Email</label>
                                <div className="form-group form-inline">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span className="material-icons">
                                                email
                                        </span>
                                        </span>
                                    </div>
                                    <input className="form-control" type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <label htmlFor="password">Senha</label>
                                <div className="form-group form-inline">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span className="material-icons">
                                                https
                                        </span>
                                        </span>
                                    </div>
                                    <input className="form-control"
                                        type="password" id="password"
                                        value={password}
                                           onChange={e => handlePassword(e.target.value)}
                                           required />

                                </div>
                                <div className="espacamento row justify-content-center">

                                    <button className="btn btn-agendamento" disabled={enviandoServidor}>Entrar</button>
                                </div>
                                <div className="espacamento row justify-content-between">
                                    <Link to="/" className="btn btn-agendamento-2"> Não é cadastrado ?</Link>
                                    <Link to="/esqueci-senha" className="espacamentoAltura btn btn-agendamento-2"> Esqueci minha senha </Link>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}
function mapStateToProps(state) {
    return {
        permissao: state.aplicacaoAgendamento.permissao,
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        redSair: state.aplicacaoAgendamento.redSair,
        areaCodigo:state.aplicacaoAgendamento.areaCodigo,
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeEmail, 
        changeToken, changeDados, changeRedSair, changeAreaCodigo }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);