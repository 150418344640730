import axios from 'axios';
const url = "https://agendamento.ws.erastogaertner.com.br/";
//const url = "http://localhost:8080/";
function connection(token) {
    const instance = axios.create({
        baseURL: url+'api',
        timeout: 100000,
        headers: { 'Authorization': 'bearer ' + token }
    });
    return instance;
}
function connectionWithoutToken() {
    const instance = axios.create({
        baseURL: url,
        timeout: 100000
    });
    return instance;
}
export { connectionWithoutToken, connection, axios };