import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { changeStatus, changeEmail, changeToken, changeRedSair,changeDados } from './../services/fieldActions';
const PrivateRoute = ({ component: Component, token,...rest }) => (
  <Route {...rest} render={(prop) => {
    return (
      (token)
      ? <Component  {...prop}/>
      : <Redirect to={{pathName:'/login', state:{from:prop.location}}} />
  )}} />);

function mapStateToProps(state) {
  return {
      token: state.aplicacaoAgendamento.token
  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeStatus, changeEmail, changeToken, changeDados,changeRedSair }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);