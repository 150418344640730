const initial_state = { permissao: false, dadosIniciais: {}, token: null,
    redSair: false, areaCodigo: false,
 email: '' };
export default function (state = initial_state, action) {
    switch (action.type) {
        case 'TOOGLE_PERMISSAO':
            return { ...state, permissao: action.payload.permissao };
        case 'TOOGLE_DADOSINICIAIS':
            return { ...state, dadosIniciais: action.payload.dadosIniciais };
        case 'TOOGLE_EMAIL':
            return { ...state, email: action.payload.email };
        case 'TOOGLE_TOKEN':
            return { ...state, token: action.payload.token };
        case 'TOOGLE_REDSAIR':
            return {...state, redSair:action.payload.status};
        case 'TOOGLE_AREACODIGO':
            return {...state, areaCodigo:action.payload.areaCodigo};
        default:
            return state;
    }

}