import React from 'react';
import { Link } from 'react-router-dom';
import './menu.css';
export default function Menu(props) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-agendamento bg-agendamento">
                <Link className="navbar-brand" to="/agendamento">Consulta de Agenda</Link>
                <div className="collapse navbar-collapse" id="navbarNav" >
                </div>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle itemNome"
                        id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {props.nomePessoa}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item " to="/agendamento">Home</Link>
                        <Link className="dropdown-item " to="/alterar-senha">Alterar Senha</Link>
                        <Link className="dropdown-item " to="/sair">Sair</Link>
                    </div>
                </li>

            </nav>

        </>
    );
}