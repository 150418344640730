import React from 'react';
import Avatar from './avatar.png';
import './estilizacao.css';
export default function BlocoAgendamento(props) {
    return (
        
        <div className="container bodyCorpo" key={props.data.id}>
            <div className="card border-agendamento mb-3">
                <div className="card-header">Consulta</div>
                <div className="card-body text-agendamento row">
                    <img src={Avatar} className="img-fluid img-avatar col-md-3"/>
                    <div className="col-md-9 linhaCentro">
                        <h5 className="card-title">{props.data.agenda}</h5>
                        <p className="card-text">Data: <b>{props.data.data}</b>
                            <br />
                    Status: <b className="statusNome">{props.data.status}</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}