import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalPersonalizado = ({ show, handleExit }) => {
  return (
    <Modal show={show} onHide={handleExit}>
      <Modal.Dialog >
        <Modal.Header closeButton>
          <Modal.Title>Atenção !</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Apesar dos esforços para conter a disseminação de COVID-19, testemunhamos diariamente o aumento do números de casos em Curitiba a ponto de novas medidas precisarem ser tomadas para garantir a segurança dos nossos pacientes e profissionais da saúde.

            Por isso, a partir do dia 02 de julho, está proibido o acesso de acompanhantes nas dependências da instituição, EXCETO nos casos previstos em lei (maiores de 60 anos e menores de 18 anos) e de pacientes que fazem uso de cadeira de rodas ou acamados.

            A troca de acompanhantes poderá ser feita apenas uma vez ao dia, exclusivamente das 07h às 08h e das 19h às 20h. Diariamente, a partir das 16h, o boletim médico é repassado aos familiares na tenda de acolhimento.
        </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleExit}>Compreendi e desejo continuar!</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
}

export default ModalPersonalizado;
