import React, { useState } from 'react';
import './EsqueciSenha';
import { Link } from 'react-router-dom';
import { connectionWithoutToken } from './../services/api';
import Footer from './../footer/index';
function EsqueciSenha() {
    const [alerta, setAlerta] = useState({ msg: '', type: '' });
    const [email, setEmail] = useState('');
    const [enviandoServidor, setEnviandoServidor] = useState(false);
    function formSubmit(e) {
        e.preventDefault();
        forgetSenha();
    }
    async function forgetSenha() {
        setEnviandoServidor(true);
        setAlerta({ msg: 'Aguarde !', type: 'alert alert-warning' });
        let returnError = false;
        let dados = await connectionWithoutToken()
            .post('/iforgotthepassword',
                { email: email })
            .catch((res) => {
                returnError = true;
            });
            setEnviandoServidor(false);
        if (returnError) {
            setAlerta({
                msg: 'Ocorreu um erro ao comunicar com o servidor!',
                type: 'alert alert-danger'
            });
        }
        if (dados.data === undefined) {
            return;
        }
        dados = dados.data;
        if (dados.status === true) {
            setAlerta({
                msg: 'Foi enviado um e-mail com sucesso para você, no e-mail contem a nova senha!',
                redi: <Link to="/login">Ir para o login!</Link>,
                type: 'alert alert-success'
            });
        } else {
            setAlerta({
                msg: 'Não foi encontrado seu e-mail!, você se cadastrou?',
                redi: <Link to="/">Se cadastrar!</Link>,
                type: 'alert alert-danger'
            });
        }
    }
    return (
        <div className="all">
            <div className="container">
                <div className="login">
                    <div className="header">
                    <h6>SISTEMA PARA CONSULTA DE AGENDA</h6>
            <p className="subtitulo">Caso você não tenha agenda durante os próximos meses 
              o seu cadastro não será encontrado.</p>
                    </div>
                    <div className="body">
                        <Link to="/login" className="btn  btn-agendamento-2">
                            <span className="material-icons">
                                arrow_back
                        </span>
                        </Link>
                        <h2 className="subtitulo">Esqueci minha senha</h2>
                        <div className={alerta.type}>
                            <p className="Fontblack">{alerta.msg}</p>
                            {(alerta.redi !== undefined) ? alerta.redi : ''}
                        </div>
                        <form onSubmit={e => formSubmit(e)}>
                            <label htmlFor="email">Email</label>
                            <div className="form-group form-inline">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="material-icons">
                                            email
                                        </span>
                                    </span>
                                </div>
                                <input type="email"
                                    placeholder="Email"
                                    id="email"
                                    className="form-control"
                                    name="email" value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required />
                            </div>
                            <div className="row justify-content-center">
                                <button className="btn btn-primary" disabled={enviandoServidor}>Enviar</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default EsqueciSenha;