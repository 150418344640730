import React from 'react'
import './footerStyle.css';
import LogoGenya from './genya-logo.png';
export default function Footer() {
  function redirectGenya() {
    window.open('https://genya.com.br/', '_blank');
  }
  return (
    <>

      <div className="bloco-logo">

        <div className="logoBox">
            <p>
                Qualquer dúvida entre em contato com a nossa central de relacionamento através do site<br/>
                <a href="https://erastogaertner.com.br/centralderelacionamento" target="_blank">
                    https://erastogaertner.com.br/centralderelacionamento</a>
            </p>
          <img src={LogoGenya}
            className="img-fluid imgFooter"
            onClick={() => { redirectGenya() }}
          />
          <p>Copyright <b>©{new Date().getFullYear()}</b> - Hospital Erasto Gaertner</p>
        </div>
      </div>
    </>
  );
}